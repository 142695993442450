<template>
  <v-list-group>
    <!-- TOC Tier Header -->
    <template v-slot:activator>
      <v-list-item @click="scrollToElement(path)">
        <v-list-item-content>
          <v-list-item-title 
            v-html="formatTierText(tier, path)" 
            class="toc-subitem">
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <!-- Display the listItems if available -->
    <v-list v-if="tier.listItems && tier.listItems.length" dense>
      <v-list-group 
        v-for="(listItem, listIndex) in tier.listItems" 
        :key="'list-item-' + path.join('-') + '-' + listIndex" 
        sub-group
        :append-icon="checkSubtier(listItem)"
      >
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ listItem.listTitle ? listItem.listTitle : "Lists" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- Loop through each item in the listItem and display it -->
        <v-list-item v-for="(item, itemIndex) in listItem.items" :key="'list-item-' + path.join('-') + '-' + listIndex + '-' + itemIndex">
          <v-list-item-content>
            <v-list-item-title
              @click="scrollToList([...path, listIndex, itemIndex])"
              v-html="formatListItemText(item)"
              class="toc-subitem"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <!-- Continue rendering subTiers if available -->
    <v-list v-if="tier.subTiers && tier.subTiers.length" dense class="mx-4">
      <toc-item
        v-for="(subTierItem, subTierIndex) in tier.subTiers"
        :key="'toc-subTier-' + path.join('-') + '-' + subTierIndex"
        :tier="subTierItem"
        :path="[...path, subTierIndex]"
        @scrollToElement="scrollToElement"
        @scrollToList="scrollToList"
      />
    </v-list>
  </v-list-group>
</template>

<script>
export default {
  name: "TocItem",
  props: {
    tier: Object,
    path: {
      type: Array,
      required: true,
    },
  },
  methods: {
    checkSubtier(tier) {
      return tier.subTiers && tier.subTiers.length > 0 ? 'mdi-chevron-down' : '';
    },
    scrollToElement(path) {
      this.$emit("scrollToElement", path);
    },
    scrollToList(path) {
      this.$emit("scrollToList", path);
    },
    formatPath(path) {
      const formattedPath = path.map((index) => index + 1);
      return formattedPath.join(".");
    },
    formatTierText(tier, path) {

      const number = tier?.number || " ";
      const name = tier?.name || `Subheader ${this.formatPath(path)}`;
      return `<strong>${number}</strong> ${name}`;
    },
    formatListItemText(item) {
      return `<span class="text-caption"> * ${item.no}<span>`;
    },
  },
};
</script>

<style scoped>
.toc-subitem {
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  max-width: 150px;
}

.toc-subitem:hover,
.toc-item:hover {
  color: #4B70F5;
}

</style>
