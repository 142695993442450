<template>
  <div :id="generateId('tier', path)" class="tier-container">
    <h2 v-if="tier?.number" class="text-center text-h5 font-weight-bold mt-2">{{ tier?.number }}</h2>
    <h2 v-if="tier?.name" class="text-center text-h5 font-weight-medium">{{ tier?.name }}</h2>
    
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8" class="mb-2">
        <span class="text-subtitle-1 font-weight-black" v-if="tier?.content" v-html="tier.content"></span>
      </v-col>
    </v-row>
    
    <!-- List Items -->
    <div v-if="tier?.listItems?.length">
      <div class="my-3" v-for="(listGroup, listIdx) in tier.listItems" :key="listIdx">
        <h4 v-if="listGroup?.listTitle" class="text-center text-h5 font-weight-medium">{{ listGroup?.listTitle }}</h4>
        <v-col v-for="(item, itemIdx) in listGroup.items" :key="itemIdx">
          <p :id="generateId('list', [...path, listIdx, itemIdx])" class="blockquote-title text-title mb-1 font-weight-black">{{ item.no }}</p>
          <v-divider inset class="my-2"></v-divider>
          <p class="blockquote-body text-body-1" v-html="item.contents"></p>
        </v-col>
      </div>
    </div>

    <v-divider v-if="tier?.name" class="my-4"></v-divider>

    <!-- Recursive Sub-tiers -->
    <div v-if="tier?.subTiers?.length">
      <recursive-tier
        v-for="(subTier, subIdx) in tier.subTiers"
        :key="subIdx"
        :tier="subTier"
        :path="[...path, subIdx]"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecursiveTier',
  props: {
    tier: {
      type: Object,
      required: true,
    },
    path: {
      type: Array,
      required: true,
    }
  },
  methods: {
    generateId(el, path) {
      let id = '';
      if (el === 'tier') {
        id = `tier-${path.join('-')}`;
      } else if (el === 'list') {
        id = `list-${path.join('-')}`;
      }
      return id;
    },
  }
};
</script>

<style scoped>
.tier-container {
  margin-bottom: 20px;
}
</style>
