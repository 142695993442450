<template>
  <v-container style="max-width: 1300px;" class="full-text-law">
    <v-row>
      <!-- Main Content Area -->
      <v-col
        :cols="12"
        :md="tocDrawer && !isMobile ? 9 : 12"
        style="min-height: 600px;"
      >
        <v-card class="my-2">
          <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000" multi-line top>
            <v-layout align-center pr-4>
              <v-icon class="pr-3" dark large>{{ snackIcon }}</v-icon>
              <v-layout column>
                <div><strong>{{ snackTitle }}</strong></div>
                <div>{{ response }}</div>
              </v-layout>
            </v-layout>
          </v-snackbar>

          <div class="text-justify pa-3" :class="{ 'ma-10': $vuetify.breakpoint.smAndUp, 'ma-3': $vuetify.breakpoint.xsOnly }">
            <div ref="body">
              <!-- Law Header -->
              <v-row align="center" justify="space-between" class="mb-2">
                <v-col cols="auto">
                  <span class="text-subtitle-2 font-weight-medium" v-if="law?.aka" v-html="law?.aka"></span>
                  <span class="font-weight-medium" v-if="getLawCode(law?.lawTag, law?.lawNo)" v-html="getLawCode(law?.lawTag, law?.lawNo)"></span>
                </v-col>
                <v-col cols="auto">
                  <span class="text-overline">{{ formattedDate(law?.approvalDate) }}</span>
                </v-col>
              </v-row>

              <!-- Law Title and Subtitle -->
              <v-row justify="center">
                <v-col cols="12" md="8" lg="6" class="text-center mb-2">
                  <span class="text-h4 font-weight-bold" :class="{ 'text-h5': $vuetify.breakpoint.xsOnly }" v-html="law?.officialTitle"></span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="10" lg="8" class="text-center mb-2">
                  <span class="text-subtitle-1 font-weight-black" v-if="law?.longTitle" v-html="law?.longTitle"></span>
                </v-col>
              </v-row>
              <hr class="my-3"/>

              <!-- Recursive Law Content with ID for ScrollSpy -->
              <div v-if="law?.contents?.length">
                <recursive-tier 
                  v-for="(content, contentIdx) in law.contents" 
                  :key="contentIdx" 
                  :tier="content" 
                  :path="[contentIdx]"
                />
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      
      <!-- Right Navigation Drawer for TOC -->
      <v-navigation-drawer
        v-model="tocDrawer"
        clipped
        permanent
        fixed
        right
        :width="tocDrawer ? 350 : 35"
        style="height: 100vh; top: 7rem; max-height: calc(100% - 250px);"
      >
        <!-- Floating Action Button to Collapse/Expand Drawer -->
        <v-btn
          fab
          color="secondary"
          absolute
          small
          top
          left
          :loading="loading"
          :style="{ top: '1%', transform: 'translate(-90%, -20%)' }"
          @click="tocDrawer = !tocDrawer"
        >
          <v-icon>{{ tocDrawer ? 'mdi-bookmark-outline' : 'mdi-bookmark-off-outline' }}</v-icon>
        </v-btn>

        <!-- TOC Content (Displays only after loading is complete) -->
        <div v-if="tocDrawer" class="toc-content text-body-2">
          <h6 class="text-overline text-center mt-3">Contents</h6>
          <hr class="mb-2" />
          <v-card flat :loading="loading" style="background-color: transparent;">
            <!-- Main List -->
            <v-list dense>
              <v-list-group 
                v-for="(tier, tierIndex) in law.contents" 
                :key="'toc-tier-' + tierIndex"
                :append-icon="checkSubtier(tier)"
              >
                <!-- TOC Tier Header -->
                <template v-slot:activator>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                          @click="scrollToElement([tierIndex])"
                          class="toc-item"
                          v-html="formatTierText(tier, tierIndex)"
                      >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <!-- TOC List Items -->
                <v-list v-if="tier?.listItems && tier?.listItems?.length > 0" class="toc-sublist">
                  <v-list-group 
                    v-for="(listItem, listIndex) in tier.listItems" 
                    :key="'list-item-' + tierIndex + '-' + listIndex"
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-caption">
                            {{ listItem.listTitle ? listItem.listTitle  : "Lists" }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <v-list>
                      <v-list-item v-for="(item, itemIndex) in listItem.items" :key="'list-item-' + tierIndex + '-' + listIndex + '-' + itemIndex">
                        <v-list-item-content>
                          <v-list-item-title
                            @click="scrollToList([tierIndex, listIndex, itemIndex])" 
                            class="toc-subitem" 
                            v-html="formatListItemText(item)"
                          >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-list-group>
                </v-list>

                <!-- TOC Sub Tiers -->
                <v-list v-if="tier.subTiers.length" class="toc-sublist mx-4" dense>
                  <TocItem
                    v-for="(subTier, subTierIndex) in tier.subTiers"
                    :key="'toc-subTier-' + tierIndex + '-' + subTierIndex"
                    :tier="subTier"
                    :path="[tierIndex, subTierIndex]"
                    @scrollToElement="scrollToElement"
                    @scrollToList="scrollToList"
                  />
                </v-list>
              </v-list-group>
            </v-list>
          </v-card>
          
        </div>
      </v-navigation-drawer>
    </v-row>

    <!-- Floating Action Button -->
    <transition name="fade">
      <v-btn
        v-show="showScrollTopButton"
        fab
        fixed
        bottom
        right
        color="primary"
        @click="scrollToTop"
        class="scroll-to-top-btn"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </transition>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { lawsCollection, sectionsCollection } from '@/firebaseDb';
import RecursiveTier from './LawViewContent.vue';
import TocItem from './editor/TierToc.vue';

export default {
  components: {
    RecursiveTier,
    TocItem,
  },
  data() {
    return {
      tocDrawer: false,
      isMobile: false,
      loading: true,
      lawId: this.$route.params.id,
      law: { contents: [] },
      snackbar: false,
      snackColor: 'black',
      snackIcon: '',
      snackTitle: '',
      response: '',
      showScrollTopButton: false,
    };
  },
  mounted() {
    this.checkIfMobile(); 
    window.addEventListener('resize', this.checkIfMobile);
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapGetters(['darkTheme']),
    themeClass() {
      return this.darkTheme ? 'dark-theme' : 'light-theme';
    },
    showTocDrawer() {
      return !this.isMobile;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    formatListItemText(item) {
      return `<span class="text-caption"> * ${item.no}<span>`;
    },
    formatTierText(tier, tierIndex) {
      const number = tier?.number || ' ';
      const name = `${tier?.name || `<em>Content ${tierIndex + 1}</em>`}`;
      // Safely return the HTML string with tags
      return `<strong>${number}</strong> ${name}`;
    },
    checkSubtier(tier) {
      return (tier.subTiers && tier.subTiers.length > 0) || (tier.listItems && tier.listItems.length > 0) ? 'mdi-chevron-down' : '';
    },
    getLawCode(lawTag, lawNo) {
      // Return early for tags that do not require a law number
      if (lawTag === "Constitutional Document" || lawTag === "Spanish Decree") {
        return "";
      }
      const lawTagMap = {
        "Republic Act": "RA",
        "Executive Order": "EO",
        "Presidential Decree": "PD",
        "Batas Pambansa": "BP",
        "Letters of Instruction": "LOI",
        "Commonwealth Acts": "CA",
        "Acts of Legislature": "Act"
      };
      // Look up the prefix for the lawTag, if it exists
      const prefix = lawTagMap[lawTag] || "";
      return lawNo ? ` | <strong>${prefix} ${lawNo}</strong>` : "";
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleScroll() {
      this.showScrollTopButton = window.scrollY > 100;
    },
    scrollToList(path){
      const el = 'list';
      this.scrollToView(el, path);
    },
    scrollToElement(path) {
      const el = 'tier';
      this.scrollToView(el, path);
    },
    scrollToView(el, path) {
      let targetId = '';
      if (el === 'tier') {
        targetId = `tier-${path.join('-')}`;
      } else if (el === 'list') {
        targetId = `list-${path.join('-')}`;
      }
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 100,
          behavior: 'smooth',
        });
        const originalTextColor = window.getComputedStyle(targetElement).color;
        targetElement.style.color = '#3A9679';
        // Reset the text color after 1 second
        setTimeout(() => {
          targetElement.style.color = originalTextColor;
        }, 1000);
        // Close the drawer if on mobile
        if (this.isMobile) {
          this.tocDrawer = false;
        }
      }
    },
    generateId(path) {
      return `tier-${path.join('-')}`;
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 960;
    },
    async fetchLaw() {
      try {
        const lawDoc = await lawsCollection.doc(this.lawId).get();
        if (lawDoc.exists) {
          let lawData = lawDoc.data();
          
          this.law = lawData;
          await this.fetchSectionsInContents();
          this.$nextTick(() => {
            this.loading = false;
          });
        } else {
          this.showError('Law not found.');
        }
      } catch (error) {
        console.log(error.message);
        this.showError(`Error fetching law: ${error.message}`);
      }
    },
    async fetchSectionsInContents() {
      try {
        // Iterate through top-level tiers
        for (const tier of this.law.contents) {
          // Fetch listItems at the top level
          if (tier.listItems && tier.listItems.length > 0) {
            for (let listGroup of tier.listItems) {
              if (listGroup.id) {
                const docRef = sectionsCollection.doc(listGroup.id);
                const doc = await docRef.get();
                if (doc.exists) {
                  const fetchedData = doc.data();
                  // Replace the `items` field (which is a reference) with the fetched `listItems`
                  this.$set(listGroup, 'items', fetchedData.listItems || []);
                  this.$set(listGroup, 'listTitle', fetchedData.listTitle || '');
                } else {
                  console.error(`Section with ID ${listGroup.id} does not exist`);
                }
              }
            }
          }
          // Fetch listItems within subTiers recursively
          if (tier.subTiers && tier.subTiers.length > 0) {
            await this.fetchSubTiers(tier.subTiers); 
          }
        }
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    },

    // Recursive function to fetch listItems within subTiers
    async fetchSubTiers(subTiers) {
      for (const subTier of subTiers) {
        // Fetch listItems within the sub-tiers
        if (subTier.listItems && subTier.listItems.length > 0) {
          for (let listGroup of subTier.listItems) {
            if (listGroup.id) {
              const docRef = sectionsCollection.doc(listGroup.id);
              const doc = await docRef.get();
              if (doc.exists) {
                const fetchedData = doc.data();
                // Replace the `items` field with the fetched `listItems`
                this.$set(listGroup, 'items', fetchedData.listItems || []);
                this.$set(listGroup, 'listTitle', fetchedData.listTitle || '');
              } else {
                console.error(`Section with ID ${listGroup.id} does not exist`);
              }
            }
          }
        }
        // Recursively fetch nested subTiers
        if (subTier.subTiers && subTier.subTiers.length > 0) {
          await this.fetchSubTiers(subTier.subTiers);  // Recursive call for deeper sub-tiers
        }
      }
    },

    showError(message) {
      this.snackbar = true;
      this.snackColor = 'red';
      this.snackIcon = 'mdi-alert';
      this.snackTitle = 'Error';
      this.response = message;
    },
    formattedDate(dateString) {
      return dateString;
    },
  },
  created() {
    this.fetchLaw();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.checkIfMobile);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap');

.full-text-law {
  font-family: 'Merriweather', Georgia, serif !important;
}

.full-text-law >>> .text-overline,
.full-text-law >>> .text-h4,
.full-text-law >>> .text-h5,
.full-text-law >>> .text-h6,
.full-text-law >>> .text-body-1,
.full-text-law >>> .text-title,
.full-text-law >>> p,
.full-text-law >>> h3,
.full-text-law >>> h4,
.full-text-law >>> h5,
.full-text-law >>> h6,
.full-text-law >>> div,
.full-text-law >>> span {
  font-family: 'Merriweather', Georgia, serif !important;
}

/* Paragraph styling */
.full-text-law >>> p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

.full-text-law >>> .blockquote-body {
  padding-left: 1rem;
  line-height: 1.4;
}

.full-text-law >>> .blockquote-title {
  font-size: 1.1rem;
  font-style: italic;
}

.full-text-law >>> .blockquote-content {
  font-size: 1.1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.full-text-law >>> blockquote {
  font-family: 'Merriweather', Georgia, serif;
  font-style: italic;
  padding: 10px 20px;
  border-left: 5px solid #ccc;
  margin: 20px 0;
  line-height: 1.4;
}

.toc-title,
.toc-item,
.toc-caption {
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
}

.toc-subitem,
.toc-item {
  cursor: pointer;
}

.toc-caption {
  margin-left: 2.5rem;
}

.toc-subitem:hover,
.toc-item:hover {
  color: #4B70F5;
}

.v-navigation-drawer--mini-variant, 
.v-navigation-drawer {
  overflow: visible !important;
}
</style>